<template>
  <div class="manage-project-sales-reservation">
    <div class="container fluid">
      <reservation-table
        :getReservationsAPI="reservationAPI.getReservations"
        :approveReservationAPI="reservationAPI.approveReservation"
        :cancelReservationAPI="reservationAPI.cancelReservation"
        :unitId="$route.params.id"
        detailRouteName="ManageProjectReservationDetails"
      ></reservation-table>
    </div>
  </div>
</template>

<script>
import ReservationTable from "@/components/GlobalComponents/Shared/ProjectSales/Reservation/ReservationTable";
import { projectReservation as reservationAPI } from "@/api";

export default {
  components: {
    ReservationTable
  },
  mixins: [],
  props: {
    isAssigned: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return { reservationAPI };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss">
</style>
